[dir="rtl"] {
    .ms-bulk-purchase {
        &__content {
            &__collapse-button {
                &::after {
                    margin-right: auto;
                    margin-left: unset;
                    padding: 0 8px;
                }
            }
        }
    }
}
