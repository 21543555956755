$msv-content-block-error-text-margin: 40px 0;
$msv-content-block-error-details-margin-desktop: 16% 11%;
$msv-content-block-error-details-margin-tablet: 34% 15%;
$msv-content-block-error-details-margin-mobile: 41% 5%;

:root {
    // Text theme
    --msv-content-block-error-textheme-dark: var(--msv-font-primary-color);
    --msv-content-block-error-textheme-light: #{$msv-white};
}

.ms-content-block {
    &[data-m-layout="hero-error"] {
        position: relative;

        .ms-content-block__image,
        .ms-content-block__image img {
            width: $msv-width-full;
        }

        .ms-content-block__image svg {
            width: $msv-width-full;
        }

        .ms-content-block__details {
            margin: $msv-content-block-error-details-margin-desktop;
            display: flex;
            flex-direction: column;
            align-content: center;
            position: absolute;
            text-align: left;
            align-items: flex-start;
            top: 0;
            left: 0;
            right: 0;

            @media (max-width: $msv-breakpoint-m) {
                margin: $msv-content-block-error-details-margin-mobile;
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
                margin: $msv-content-block-error-details-margin-tablet;
            }

            .ms-content-block__title {
                @include font-callout-bold-l();
                color: var(--msv-content-block-error-textheme-light);

                @media (max-width: $msv-breakpoint-m) {
                    @include font-callout-bold-s();
                    color: var(--msv-content-block-error-textheme-light);
                }

                @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                    @include font-callout-bold-m();
                    color: var(--msv-content-block-error-textheme-light);
                }
            }

            .ms-content-block__text {
                margin: $msv-content-block-error-text-margin;

                @include font-body-regular-m();
                color: var(--msv-content-block-error-textheme-light);
            }

            .msc-cta__primary {
                @include primary-button-dark();
            }
        }

        &.textplacement__left .ms-content-block__details {
            text-align: left;
            align-items: flex-start;
        }

        &.textplacement__center .ms-content-block__details {
            align-items: center;
            text-align: center;
        }

        &.textplacement__right .ms-content-block__details {
            text-align: right;
            align-items: flex-end;
        }

        &.texttheme__light .ms-content-block__title,
        &.texttheme__light .ms-content-block__text {
            color: var(--msv-content-block-error-textheme-light);
        }

        &.texttheme__dark .ms-content-block__title,
        &.texttheme__dark .ms-content-block__text {
            color: var(--msv-content-block-error-textheme-dark);
        }

        &.texttheme__dark .msc-cta__primary {
            @include primary-button-light();
        }

        /**
        * Text over image by default only in modern browsers.
        * We must reflow the text to bottom in IE 11 because it doesn't support srcset and the image will be too short to put text over it.
        */

        @supports (object-fit: cover) {
            .ms-content-block__image {
                object-fit: cover;
            }
        }
    }
}
