// Import bootstrap css

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";
@import "../00-settings/responsive-breakpoints";
@import "bootstrap/scss/bootstrap";



@font-face {
    font-family: 'Nunito', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;1,400;1,700&display=swap');
    src: url('../../../Nunito-Black'),
    url('../../../Nunito-BlackItalic'),
    url('../../../Nunito-Bold'),
    url('../../../Nunito-BoldItalic'),
    url('../../../Nunito-ExtraBold'),
    url('../../../Nunito-ExtraBoldItalic'),
    url('../../../Nunito-ExtraLightItalic'),
    url('../../../Nunito-Italic'),
    url('../../../Nunito-Italic-VariableFont_wght'),
    url('../../../Nunito-Light'),
    url('../../../Nunito-LightItalic'),
    url('../../../Nunito-Medium'),
    url('../../../Nunito-MediumItalic'),
    url('../../../Nunito-Regular'),
    url('../../../Nunito-SemiBold'),
    url('../../../Nunito-SemiBoldItalic'),
    url('../../../Nunito-VariableFont_wght'),
    url('../../../Nunito-ExtraLight');
}

body {
    font-family: 'Nunito', sans-serif;
}


.row {
    margin: 0;
}

div[class^="col"] {
    padding: 0;
    margin: 0;
}

// Container width
body .container {
    padding-left: $msv-layout-container-padding-m;
    padding-right: $msv-layout-container-padding-m;

    @media screen and (max-width: $msv-breakpoint-m) {
        padding-left: $msv-layout-container-padding-s;
        padding-right: $msv-layout-container-padding-s;
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        padding-left: $msv-layout-container-padding-l;
        padding-right: $msv-layout-container-padding-l;
    }

    @media screen and (min-width: $msv-breakpoint-s) {
        max-width: $msv-breakpoint-xl;
        width: 100%;
    }
}
