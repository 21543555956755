$rtl-msv-details-sale-line-margin: 0 0 20px 12px;

[dir="rtl"] {
    .ms-order-confirmation {
        &__order-information-created-date {
            right: unset;
            left: 0;
        }

        &__order-summary-price {
            float: unset;

            .msc-price__actual {
                float: left;
            }
        }

        &__payment-methods {
            @media screen and (min-width: $msv-breakpoint-m) {
                float: left;
            }

            &-gift-card-amount {
                margin-left: 0;
                margin-right: 4px;
            }
        }

        &__address-phone-icon {
            padding-right: 0;
            padding-left: 15px;
        }

        &__group-delivery {
            padding-right: 0;
            padding-left: 20px;
        }

        &__sales-line {
            margin: $rtl-msv-details-sale-line-margin;
        }

        &__order-summary-heading {
            text-align: right;
        }

        @media screen and (min-width: $msv-breakpoint-m) {
            .ms-order-confirmation__payment-methods {
                &:last-child {
                    float: right;
                    width: 75%;
                    padding-right: unset;
                    padding-left: 20%;
                }
            }
        }
    }
}
