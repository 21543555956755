$msv-loyalty-signup-terms: 6px 0 6px 12px;

[dir="rtl"] {
    .ms-loyalty-signup {
        .ms-loyalty-signup__terms {
            padding: $msv-loyalty-signup-terms;
        }
    }

    .ms-account-loyalty {
        &__point-breakdown {
            justify-content: space-between;
            display: flex;
        }

        &__points-modal {
            @media screen and (max-width: $msv-breakpoint-m) {
                .msc-modal__content {
                    .msc-modal__header {
                        .msc-modal__close-button {
                            left: 0;
                            right: unset;
                        }
                    }
                }
            }
        }
    }
}
