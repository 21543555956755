$ms-accordion-drawer-button-color:rgba(44, 38, 45, 1);

.ms-accordion {
    &-header-section {
        &-toggle-button-container {
            text-align: right;
            margin-bottom: 25px;
        }

        .ms-accordion_ExpandAll,
        .ms-accordion_CollapseAll {
            background-color: $msv-white;
            border: none;
        }
    }

    &-item-section {
        .drawer.ms-accordion-item__drawer {
            border-top: 0.5px solid $msv-gray-50;
            background-color: $msv-white;
        }

        .drawer__button.msc-btn.btn-block {
            width: 100%;
            height: 68px;
            display: flex;
            background-color: $msv-white;
            justify-content: space-between;
            border: none;
            outline: none;

            &[aria-expanded="true"] {
                @include add-icon($msv-Remove, after);

                &::after {
                    color: $ms-accordion-drawer-button-color;
                    line-height: 20px;
                    float: right;
                    margin-bottom: auto;
                    margin-top: auto;
                }
            }

            &[aria-expanded="false"] {
                @include add-icon($msv-Add, after);

                &::after {
                    color: $ms-accordion-drawer-button-color;
                    line-height: 20px;
                    float: right;
                    margin-bottom: auto;
                    margin-top: auto;
                }
            }

            &:focus {
                border: 0.5px solid #{$msv-blue};
            }

            &:focus:not(:focus-visible) {
                outline: none;
            }
        }

        .drawer__buttontext.__start {
            @include font-body-bold-s();
            float: left;
            margin-bottom: auto;
            margin-top: auto;
        }

        .ms-text-block {
            margin-bottom: 25px;
            margin-top: 0;
        }
    }
}
