// Base class
.msc-tooltip {
    position: absolute;
    z-index: $zindex-tooltip;
    display: block;
    margin: $tooltip-margin;
    // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
    // So reset our font and text properties to avoid inheriting weird values.

    @include reset-text();
    @include font-size($tooltip-font-size);
    // Allow breaking very long words so they don't overflow the tooltip's bounds
    word-wrap: break-word;
    opacity: 0;

    &.show { opacity: $tooltip-opacity; }

    .msc-arrow {
        position: absolute;
        display: block;
        width: $tooltip-arrow-width;
        height: $tooltip-arrow-height;

        &::before {
            position: absolute;
            content: "";
            border-color: transparent;
            border-style: solid;
        }
    }
}

.msc-bs-tooltip-top {
    padding: $tooltip-arrow-height 0;

    .msc-arrow {
        bottom: 0;

        &::before {
            top: 0;
            border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
            border-top-color: $tooltip-arrow-color;
        }
    }
}

.msc-bs-tooltip-right {
    padding: 0 $tooltip-arrow-height;

    .msc-arrow {
        left: 0;
        width: $tooltip-arrow-height;
        height: $tooltip-arrow-width;

        &::before {
            right: 0;
            border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
            border-right-color: $tooltip-arrow-color;
        }
    }
}

.msc-bs-tooltip-bottom {
    padding: $tooltip-arrow-height 0;

    .msc-arrow {
        top: 0;

        &::before {
            bottom: 0;
            border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
            border-bottom-color: $tooltip-arrow-color;
        }
    }
}

.msc-bs-tooltip-left {
    padding: 0 $tooltip-arrow-height;

    .msc-arrow {
        right: 0;
        width: $tooltip-arrow-height;
        height: $tooltip-arrow-width;

        &::before {
            left: 0;
            border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
            border-left-color: $tooltip-arrow-color;
        }
    }
}

.msc-bs-tooltip-auto {
    &[x-placement^="top"] {
        @extend .msc-bs-tooltip-top;
    }

    &[x-placement^="right"] {
        @extend .msc-bs-tooltip-right;
    }

    &[x-placement^="bottom"] {
        @extend .msc-bs-tooltip-bottom;
    }

    &[x-placement^="left"] {
        @extend .msc-bs-tooltip-left;
    }
}

// Wrapper for the tooltip content
.msc-tooltip-inner {
    max-width: $tooltip-max-width;
    padding: $tooltip-padding-y $tooltip-padding-x;
    color: $tooltip-color;
    text-align: center;
    background-color: $tooltip-bg;

    @include border-radius($tooltip-border-radius);
}
