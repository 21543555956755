$msv-checkout-gift-card-input-fields-padding-left: 20px;

[dir="rtl"] {
    .ms-checkout-gift-card {
        &__input-pin-fields,
        &__input-num-fields {
            padding-right: 0;
            padding-left: $msv-checkout-gift-card-input-fields-padding-left;
        }

        &__input-error::before {
            margin-right: 0;
            margin-left: 10px;
        }
    }
}
