$msc-invoice-details-product-details-padding-right: 5px;

[dir="rtl"] {
    .msc-invoice-details {
        &__header {
            &__invoice-info-container {
                &__order-info-container {
                    &__order-info {
                        &__details {
                            &__order-date {
                                padding-right: unset;
                                border-right: unset;
                                padding-left: 10px;
                                border-left: solid 1px;
                            }
                        }
                    }
                }

                &__amount-summary {
                    @media only screen and (min-width: $msv-breakpoint-l) {
                        text-align: left;
                    }
                }
            }
        }

        &__products {
            &__product-line {
                &__product-info-container {
                    &__product-details {
                        padding-left: 0;
                        padding-right: $msc-invoice-details-product-details-padding-right;
                    }
                }
            }
        }
    }
}
