$msv-business-sign-up-width: 100%;
$msv-business-sign-up-message-margin-top: 20px;
$msv-business-sign-up-message-padding-bottom: 20px;
$msv-business-sign-up-button-margin-top: 20px;
$msv-business-sign-up-button-margin-spacing: 20px;
$msv-business-sign-up-disclaimer-margin-top: 20px;
$msv-business-sign-up-form-item-margin: 40px 0 0;
$msv-business-sign-up-form-item-margin-mobile: 28px 0 0;
$msv-business-sign-up-input-spacing: 20px;
$msv-business-sign-up-text-area-height: 164px;
$msv-address-dropdown-input-height: 52px;
$msv-address-dropdown-input-padding: 16px;
$msv-business-sign-up-form-item-margin-top: 40px;
$msv-business-sign-up__create-button-border-radius: 100px;

//style presets
:root {
    // heading
    --msv-business-sign-up-heading-font-color: var(--msv-font-primary-color);

    // alert
    --msv-business-sign-up-error-alert-bg: var(--msv-error-color);
    --msv-business-sign-up-error-alert-font-color: var(--msv-font-secondary-color);
    --msv-business-sign-up-error-alert-border: var(--msv-error-color);
    --msv-business-sign-up-error-color: var(--msv-error-color);
}

.ms-business-sign-up {
    @include font-body-bold-s();
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        max-width: $msv-business-sign-up-width;
    }

    &__heading {
        @include font-heading-h3-l();
        color: var(--msv-business-sign-up-heading-font-color);
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
    }

    &__form-item {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-top: $msv-business-sign-up-form-item-margin-top;
        margin-right: $msv-business-sign-up-input-spacing;

        &.width-25 {
            flex-basis: calc(25% - #{$msv-business-sign-up-input-spacing});
        }

        &.width-50 {
            flex-basis: calc(50% - #{$msv-business-sign-up-input-spacing});
        }

        &.width-100 {
            flex-basis: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        &-CompanyEmail,
        &-Department,
        &-CompanySize {
            margin-right: 0;
        }

        &-CompanyAddress {
            margin-top: 0;
        }

        &-label {
            @include form-input-label();
        }

        &-input {
            @include form-input-box();

            &-area {
                @include form-input-box();
                height: $msv-business-sign-up-text-area-height;
            }
        }

        .msc-address-form {
            &__label {
                @include form-input-label();
            }

            &__item {
                margin: $msv-business-sign-up-form-item-margin;
                display: flex;
                flex-direction: column;
            }

            &__item-isprimary {
                display: flex;

                .msc-address-form__label {
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 10px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .msc-address-form__input-checkbox {
                    width: 20px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            &__item-county {
                display: none;
            }

            &__input-text {
                padding: 6px 8px;
            }

            .MicrosoftMap {
                position: relative;
                width: 100%;

                .as_container_search {
                    width: 100%;
                }
            }

            &__input {
                @include form-input-box();
            }

            &__dropdown {
                @include form-input-box();
            }

            &__alert {
                display: flex;
                order: 3;
            }

            &__button-save {
                @include primary-button-light();
                margin-right: $msv-address-button-save-margin-right;
            }

            &__button-cancel {
                @include secondary-button-light();
            }

            .address-form__item-invalid &__alert {
                @include font-body-regular-xs();
                text-transform: none;
                border-radius: $msv-alert-border-radius;
                position: relative;
                margin-top: 15px;

                @include add-icon($msv-times-circle, $icon-weight: $msv-outline-icon-weight);
                color: var(--msv-address-form-error-font-color);

                + input {
                    border-left: 4px solid var(--msv-address-form-error-border-color);
                }

                &::before {
                    margin-right: $msv-alert-icon-margin;
                    font-size: $msv-font-size-ml;
                }
            }
        }
    }

    &__page-error {
        @include alert();
        display: flex;
        margin-top: $msv-business-sign-up-message-margin-top;
        padding-bottom: $msv-business-sign-up-message-padding-bottom;
        width: 100%;

        @media screen and (min-width: $msv-breakpoint-m) {
            align-items: flex-end;
        }
    }

    &__item-error {
        color: var(--msv-business-sign-up-error-color);
    }

    &__create-button {
        @include primary-button-light();
        margin-top: $msv-business-sign-up-button-margin-top;
        margin-right: $msv-business-sign-up-button-margin-spacing;
        text-align: center;
        display: block;

        @media (forced-colors: active) and (prefers-color-scheme: dark) {
            border: 1px solid $msv-white;
        }

        @media (forced-colors: active) and (prefers-color-scheme: light) {
            border: 1px solid $msv-black;
        }
    }

    &__cancel-button {
        display: none;
    }

    &__disclaimer {
        margin-top: $msv-business-sign-up-disclaimer-margin-top;
        width: 100%;

        a {
            text-decoration: underline;
        }
    }

    @media only screen and (max-width: $msv-breakpoint-l) {
        &__container {
            width: $msv-business-sign-up-width;
        }

        .msc-address-form__item-threeletterisoregionname {
            display: block;
            margin-bottom: 10px;
            width: 100%;
        }

        .msc-address-form__item-phone {
            display: block;
            width: 100%;
        }

        &__form-item {
            margin: $msv-business-sign-up-form-item-margin-mobile;
            margin-right: 0;

            &.width-25,
            &.width-50,
            &.width-100 {
                flex-basis: 100%;
            }

            .msc-address-form {
                &__item {
                    margin: $msv-business-sign-up-form-item-margin-mobile;
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        &__create-button {
            margin-right: 0;
            width: 100%;

            @media (forced-colors: active) and (prefers-color-scheme: dark) {
                border: 1px solid $msv-white;
            }

            @media (forced-colors: active) and (prefers-color-scheme: light) {
                border: 1px solid $msv-black;
            }
        }

        &__cancel-button {
            display: none;
        }
    }
}
