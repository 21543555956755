$msv-checkout-margin-bottom: 28px;
$msv-checkout-margin-top: 28px;
$msv-checkout-margin-left-right: auto;
$msv-checkout-plain-container-padding-left: 0;
$msv-checkout-main-max-width: calc(100% - 400px);
$msv-checkout-side-width: 400px;
$msv-checkout-main-panel-padding-right: 64px;
$msv-checkout-guided-form-margin-bottom: 20px;
$msv-checkout-guided-card-padding-top: 32px;
$msv-checkout-guided-card-padding-bottom: 32px;
$msv-checkout-guided-card-border-top-width: 1px;
$msv-checkout-guided-card-body-margin-top: 32px;
$msv-checkout-guided-card-footer-margin-top: 40px;
$msv-checkout-guided-card-footer-margin-bottom: 8px;
$msv-checkout-place-order-button-margin: 10px;
$msv-checkout-side-control-margin-bottom: 24px;
$msv-checkout-side-control-first-margin-top: 20px;
$msv-checkout-side-control-first-margin-auto: 15px auto;
$msv-checkout-side-control-place-order-button-width: 100%;
$msv-checkout-side-control-first-padding: 10px 20px 20px 20px;
$msv-checkout-save-button-margin-left: 10px;
$msv-chckout-line-items-group-title-top: 42px;
$msv-chckout-line-items-group-title-left: 180px;
$msv-chckout-line-items-group-title-tablet-left: 160px;
$msv-checkout-line-items-group-title-margin-left: 37px;
$ms-checkout-invoice-side-control-first: 17px 54px 32px;
$msv-checkout-multiple-pickup-padding: 31px 40px 39px 41px;
$msv-checkout-side-ordersummary-heading-padding: 27px 57px 23px 54px;
$msv-checkout-side-ordersummary-items-margin: 0 19px;
$msv-checkout-cart-line-unit-of-measure-margin-left: 5px;

// checkout__lines styling
$msv-checkout-line-items-padding: 32px;
$msv-checkout-line-image-size: 108px;
$msv-checkout-line-margin-top: 20px;
$msv-checkout-line-margin-left: 20px;
$msv-checkout-line-content-width: calc(100% - 128px);
$msv-checkout-line-header-margin-bottom: 20px;
$msv-checkout-line-heading-font-weight: 700;
$msv-checkout-edit-btn-padding: 0;
$msv-checkout-edit-btn-margin-left: auto;
$msv-checkout-cart-line-quantity-margin-left: 0;
$msv-checkout-cart-line-quantity-label-margin-right: 5px;
$msv-checkout-cart-line-price-strikethrough-margin-right: 10px;
$msv-checkout-pick-up-at-store-margin-left: 128px;
$msv-checkout-pick-up-at-store-margin-top: 10px;
$msv-checkout-pick-up-at-store-margin-bottom: 20px;
$msv-checkout-email-delivery-margin-left: 128px;
$msv-checkout-email-delivery-margin-top: 10px;
$msv-checkout-email-delivery-margin-bottom: 20px;
$msv-checkout-line-items-header-margin: 27px 57px 23px 54px;

// terms-and-conditions
$msv-checkout-terms-and-conditions-margin-bottom: 10px;
$msv-checkout-terms-and-conditions-padding-right: 32px;
$msv-checkout-terms-and-conditions-padding-left: 32px;

// Mobile styles
$msv-mobile-checkout-min-width: 290px;
$msv-mobile-checkout-max-width: 100%;
$msv-mobile-checkout-padding-zero: 0;
$msv-mobile-checkout-margin: 0;
$msv-mobile-checkout-max-button-width: 100%;
$msv-mobile-checkout-place-order-margin-left: 0;
$msv-mobile-checkout-place-order-margin-top: 10px;
$msv-mobile-checkout-keep-shopping-margin-top: 20px;
$msv-mobile-checkout-header-padding: 25px 30px;
$msv-mobile-checkout-lineitems-padding-left: 20px;
$msv-mobile-checkout-lineitems-padding-right: 20px;

// Alert
$msv-checkout-error-message-padding: 22px;
$msv-checkout-error-message-margin-bottom: 20px;
$msv-checkout-error-message-width: 100%;
$msv-checkout-icon-margin-right: 8px;
$msv-checkout-error-border-radius: 10px;
$msv-checkout-error-icon-margin-right: 22px;
$msv-checkout-error-icon-padding-left: 2px;

:root {
    --msv-checkout-border: #{$msv-gray-50};
    --msv-checkout-font-size: var(--msv-body-font-size-l);
    --msv-checkout-shoppingbag-border: #{$msv-gray-50};

    // background
    --msv-checkout-side-bg: var(--msv-bg-color);
    --msv-checkout-side-multiple-pickup-bg: #{$msv-white};

    //heading
    --msv-checkout-address-heading-font-color: #{$msv-gray-20};

    // error
    --msv-checkout-error-message-bg: #{$msv-pink};
    --msv-checkout-error-message-color: #{$msv-red};

    // checkout line items
    --msv-checkout-line-heading-font-color: var(--msv-font-primary-color);
    --msv-checkout-line-title-font-color: #{$msv-gray-20};
    --msv-checkout-line-text-font-color: #{$msv-gray-20};
    --msv-checkout-line-text-font-size: var(--msv-body-font-size-m);
}

.ms-checkout {
    padding-bottom: $msv-checkout-margin-bottom;
    padding-top: $msv-checkout-margin-top;
    margin-left: $msv-checkout-margin-left-right;
    margin-right: $msv-checkout-margin-left-right;
    font-size: var(--msv-checkout-font-size);
    line-height: $msv-line-height-l;

    &__line-item {
        .msc-cart-line {
            display: flex;
            margin-top: 20px;

            &__product-image {
                position: relative;

                @include image($msv-checkout-line-image-size);
                margin-right: unset;
            }

            &__content {
                display: block;
                margin-left: 20px;
                width: calc(100% - 128px);
            }

            &__quantity {
                .msc-cart-line__product-unit-of-measure {
                    display: none;
                }
            }
        }
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
    }

    &__btn-place-order {
        &.is-busy {
            &::before {
                @include msv-icon();
                content: $msv-Spinner;
                margin-right: $msv-checkout-icon-margin-right;
                -webkit-animation: spin 1s steps(8) infinite;
                animation: spin 1s steps(8) infinite;
            }

            cursor: progress;
        }

        @include primary-button-light();
        margin-left: $msv-checkout-place-order-button-margin;
    }

    &__btn-keep-shopping {
        @include secondary-button-light();
    }

    &__error-message {
        @include font-body-regular-m();
        width: $msv-checkout-error-message-width;
        background-color: var(--msv-checkout-error-message-bg);
        color: var(--msv-checkout-error-message-color);
        padding: $msv-checkout-error-message-padding;
        margin-bottom: $msv-checkout-error-message-margin-bottom;
        border-radius: $msv-checkout-error-border-radius;
        align-items: center;
        display: flex;

        @include add-icon($msv-times-circle);

        &::before {
            margin-right: $msv-checkout-error-icon-margin-right;
            padding-left: $msv-checkout-error-icon-padding-left;
            font-size: $msv-font-size-l;
        }
    }

    &-section-container {
        padding-left: $msv-checkout-plain-container-padding-left;

        &__item.hidden {
            display: none;
        }
    }

    &__title {
        display: none;
    }

    &__main {
        flex: auto;
        max-width: $msv-checkout-main-max-width;
        padding-right: $msv-checkout-main-panel-padding-right;
        padding-left: 0;

        .ms-checkout__terms-and-conditions {
            padding-right: $msv-checkout-terms-and-conditions-padding-right;
            padding-left: $msv-checkout-terms-and-conditions-padding-left;
            margin-bottom: $msv-checkout-terms-and-conditions-margin-bottom;
        }
    }

    &__main-control {
        display: flex;
        justify-content: flex-start;

        .ms-checkout__btn-keep-shopping {
            order: 0;
        }

        .ms-checkout__btn-place-order {
            margin-right: 0;
            order: 1;
        }
    }

    &__side-control-first {
        display: block;
        justify-content: center;

        padding: $msv-checkout-side-control-first-padding;
        border: 0.5px solid $msv-gray-50;
        border-radius: 0 0 10px 10px;
        border-top: none;

        .ms-checkout__btn-place-order {
            width: $msv-checkout-side-control-place-order-button-width;
            margin: $msv-checkout-side-control-first-margin-auto;
        }

        .ms-checkout__btn-keep-shopping {
            width: 100%;
        }
    }

    &__side-control-second {
        display: none;
    }

    &__side-control {
        &-first,
        &-second {
            margin-bottom: $msv-checkout-side-control-margin-bottom;
        }
    }

    &__side {
        width: $msv-checkout-side-width;

        .msc-order-summary__heading {
            @include font-heading-h3-l();
            padding: $msv-checkout-side-ordersummary-heading-padding;

            @media screen and (max-width: $msv-breakpoint-l) {
                padding: $msv-mobile-checkout-header-padding;
            }
        }

        .msc-order-summary-wrapper {
            border-radius: 10px 10px 0 0;
            border: 0.5px solid $msv-gray-50;
            background-color: transparent;

            @media screen and (max-width: $msv-breakpoint-l) {
                border-radius: 10px;
            }

            .msc-order-summary__items {
                border: none;
                margin: $msv-checkout-side-ordersummary-items-margin;

                .msc-order-summary__line-loyalty {
                    order: 2;
                }

                .msc-order-summary__line-total-discounts {
                    order: 3;
                }

                .msc-order-summary__line-shipping {
                    order: 4;
                }

                .msc-order-summary__line-tax-amount {
                    order: 5;
                }

                .msc-order-summary__line-total {
                    order: 6;
                    padding: 24px 0;
                }
            }
        }

        .msc-invoice-summary-wrapper {
            @media screen and (min-width: $msv-breakpoint-m) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            & + .ms-checkout__side-control-first {
                border-top: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                margin-top: 0;
                padding: $ms-checkout-invoice-side-control-first;
            }
        }
    }

    &__guided-form {
        margin-bottom: $msv-checkout-guided-form-margin-bottom;
    }

    &__guided-card {
        border-top: $msv-checkout-guided-card-border-top-width solid var(--msv-checkout-border);
        padding-bottom: $msv-checkout-guided-card-padding-bottom;
        padding-top: $msv-checkout-guided-card-padding-top;
        color: $msv-gray-40;

        &-header {
            display: flex;
        }

        &.hidden {
            display: none;
        }

        &.visted,
        &.active {
            border-top: $msv-checkout-guided-card-border-top-width solid var(--msv-checkout-border);
            color: var(--msv-checkout-address-heading-font-color);
            text-decoration: none;
        }

        &-title {
            @include font-heading-h3-l();
            display: flex;
        }

        &-title-step {
            display: none;
        }

        &-body {
            margin-top: $msv-checkout-guided-card-body-margin-top;

            &.hidden {
                display: none;
            }
        }

        &-content {
            .ms-checkout-pickup {
                &__group {
                    display: flex;
                    flex-direction: column;

                    &-lines {
                        order: 1;
                    }

                    &-datetime {
                        margin: 10px 0 40px;
                    }
                }
            }
        }

        &-btn-cancel {
            @include secondary-button-light();
        }

        &-btn-save {
            @include primary-button-light();
        }

        &-btn-cancel,
        &-btn-save {
            margin-right: $msv-checkout-save-button-margin-left;

            &.is-submitting {
                &::before {
                    @include msv-icon();
                    content: $msv-Spinner;
                    margin-right: $msv-checkout-icon-margin-right;
                    -webkit-animation: spin 1s steps(8) infinite;
                    animation: spin 1s steps(8) infinite;
                }

                cursor: progress;
            }
        }

        &-btn-edit {
            @include link-regular-m();
            padding: $msv-checkout-edit-btn-padding;
            margin-left: $msv-checkout-edit-btn-margin-left;
            background: transparent;
            border: none;
        }

        &-footer {
            margin-top: $msv-checkout-guided-card-footer-margin-top;
            margin-bottom: $msv-checkout-guided-card-footer-margin-bottom;
            display: flex;
        }
    }

    &__line-items {
        background-color: var(--msv-checkout-side-bg);
        line-height: $msv-line-height-s;
        padding: $msv-checkout-line-items-padding;

        &-edit-cart-link {
            @include link-regular-s();
            align-items: center;
        }

        &-header {
            margin-bottom: $msv-checkout-line-header-margin-bottom;

            .ms-checkout__line-items-heading {
                color: var(--msv-checkout-line-heading-font-color);
                display: inline;
                text-transform: capitalize;

                @include font-heading-h3-l();
            }
        }

        &-delivery-group {
            .ms-checkout__line-items-group-title {
                font-weight: $msv-checkout-line-heading-font-weight;
                color: var(--msv-checkout-line-text-font-color);
            }

            .ms-checkout__line-items-group-title-multiple-pickup-pickupicon {
                @include add-icon($msv-roadster, before);
                padding-top: 10px;
                position: absolute;
            }

            .ms-checkout__line-items-group-title-multiple-pickup-shipicon {
                @include add-icon($msv-DeliveryTruck, before);
                padding-top: 10px;
                position: absolute;
            }

            .ms-checkout__line-items-group-title-multiple-pickup-emailicon {
                @include add-icon($msv-DeliveryTruck, before);
                padding-top: 10px;
                position: absolute;
            }

            .ms-checkout__line-items-group-title-multiple-pickup-heading {
                @include font-heading-h5-l();
                color: var(--msv-checkout-line-text-font-color);
                margin-left: $msv-checkout-line-items-group-title-margin-left;
                padding-top: 10px;
            }

            .ms-checkout__line-items-group-title-multiple-pickup-subheading {
                @include font-body-regular-m();
                color: var(--msv-checkout-line-text-font-color);
                position: absolute;
                left: $msv-chckout-line-items-group-title-left;
                top: $msv-chckout-line-items-group-title-top;

                @media screen and (max-width: $msv-breakpoint-m) {
                    left: $msv-chckout-line-items-group-title-tablet-left;
                }
            }

            .ms-checkout__pick-up-at-store {
                margin-left: $msv-checkout-pick-up-at-store-margin-left;
                margin-top: $msv-checkout-pick-up-at-store-margin-top;
                margin-bottom: $msv-checkout-pick-up-at-store-margin-bottom;

                .ms-checkout__store-location {
                    font-weight: var(--msv-font-weight-bold);
                }
            }

            .ms-checkout__email-delivery {
                margin-left: $msv-checkout-email-delivery-margin-left;
                margin-top: $msv-checkout-email-delivery-margin-top;
                margin-bottom: $msv-checkout-email-delivery-margin-bottom;

                @include font-body-regular-m();

                .ms-checkout__store-location {
                    font-weight: var(--msv-font-weight-bold);
                }
            }

            .msc-cart-line {
                margin-top: $msv-checkout-line-margin-top;

                &__product-image {
                    position: relative;
                    margin-right: unset;

                    .msc-image-container {
                        border: 0.5px solid var(--msv-checkout-border);
                        margin: unset;
                    }
                }

                &__quantity,
                &__product-variants {
                    color: var(--msv-checkout-line-text-font-color);

                    @include font-body-regular-m();
                }

                &__content {
                    display: block;
                    margin-left: $msv-checkout-line-margin-left;
                    width: $msv-checkout-line-content-width;

                    .msc-cart-line__product-title {
                        color: var(--msv-checkout-line-title-font-color);

                        @include font-body-bold-m();
                    }

                    .msc-cart-line-item-product-discount {
                        color: var(--msv-success-color);
                        font-size: $msv-font-size-m;
                    }

                    .msc-cart-line__quantity {
                        margin-left: $msv-checkout-cart-line-quantity-margin-left;

                        .quantity-label {
                            margin-right: $msv-checkout-cart-line-quantity-label-margin-right;
                        }

                        .quantity-value {
                            display: inline;
                            text-align: left;
                        }
                    }

                    .msc-cart-line__product-price {
                        margin-left: $msv-checkout-cart-line-quantity-margin-left;
                        text-align: left;
                        display: flex;
                        flex-wrap: wrap;

                        @include font-body-regular-m();

                        .msc-cart-line__product-unit-of-measure {
                            margin-left: $msv-checkout-cart-line-unit-of-measure-margin-left;
                        }

                        .msc-price__strikethrough {
                            color: $msv-gray-40;
                            display: inline;
                            margin-right: $msv-checkout-cart-line-price-strikethrough-margin-right;
                            text-decoration-line: line-through;
                        }

                        .msc-price__actual {
                            color: var(--msv-checkout-line-text-font-color);
                            display: inline-block;
                        }
                    }

                    .msc-cart-line__product-unit-price {
                        display: none;
                    }

                    .msc-cart-line__product-discount {
                        @include font-body-regular-m();
                        color: $msv-blue;

                        .msc-cart-line-item-product-discount-price {
                            margin-left: 10px;
                        }
                    }

                    .msc-cart-line__product-savings {
                        margin-left: 0;
                        text-align: left;
                        // hide extra price component
                        display: none;
                    }

                    .msc-cart-line__freight {
                        @include font-body-regular-m();
                    }
                }
            }
        }
    }

    .multiple-pickup {
        border-top: 1px solid var(--msv-checkout-shoppingbag-border);
        position: relative;
        padding: $msv-checkout-multiple-pickup-padding;
    }

    .multiple-pickup-enabled {
        background-color: var(--msv-checkout-side-multiple-pickup-bg);
        border: 0.5px solid $msv-gray-50;
        border-radius: 10px;
        padding: 0;
        margin-top: 20px;

        .ms-checkout__line-items-header {
            margin: $msv-checkout-line-items-header-margin;
            display: flex;
            justify-content: space-between;

            @media (max-width: $msv-breakpoint-l) {
                padding: $msv-mobile-checkout-header-padding;
                margin: 0;
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__main {
            min-width: $msv-mobile-checkout-min-width;
            max-width: $msv-mobile-checkout-max-width;
            padding-right: $msv-mobile-checkout-padding-zero;
            padding-left: $msv-mobile-checkout-padding-zero;

            .ms-checkout__terms-and-conditions {
                display: none;
            }
        }

        &__side {
            width: $msv-mobile-checkout-max-width;
        }

        &__guided-card {
            &-body {
                padding-left: $msv-mobile-checkout-padding-zero;
                padding-right: $msv-mobile-checkout-padding-zero;
            }

            &-btn-save {
                width: $msv-mobile-checkout-max-button-width;
                margin-right: 10px;
            }

            &-btn-cancel {
                width: $msv-mobile-checkout-max-button-width;
            }
        }

        &__body {
            width: $msv-mobile-checkout-max-button-width;
        }

        margin-right: $msv-mobile-checkout-margin;
        margin-left: $msv-mobile-checkout-margin;

        &__main-control,
        &__side-control-first {
            display: none;
        }

        &__side-control-second {
            background: var(--msv-checkout-side-bg);
            display: block;
        }

        .ms-checkout__btn-place-order {
            margin-left: $msv-mobile-checkout-place-order-margin-left;
            margin-top: $msv-mobile-checkout-place-order-margin-top;
        }

        .ms-checkout__btn-keep-shopping {
            margin-top: $msv-mobile-checkout-keep-shopping-margin-top;
            line-height: $msv-line-height-xl;
            justify-content: center;
            padding-left: 5px;
            padding-right: 5px;
        }

        &__line-items {
            .ms-checkout__line-items-header,
            .ms-checkout__line-items-delivery-group {
                padding-left: $msv-mobile-checkout-lineitems-padding-left;
                padding-right: $msv-mobile-checkout-lineitems-padding-right;
                margin: 0;
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-l) {
        .ms-checkout__btn-keep-shopping,
        .ms-checkout__btn-place-order {
            width: $msv-mobile-checkout-max-button-width;
        }

        .ms-checkout__btn-place-order {
            margin-top: 20px;
            margin-left: 0;
        }

        .ms-checkout__main-control {
            flex-direction: column;
        }
    }
}
